import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavModule } from './component/side-nav-component/side-nav.module';
import { WrapperModule } from './component/wrapper-component/wrapper.module';
import { PlayerModule } from './component/player-component/player.module';
import { IconsRegister } from 'src/lib/provider/icons/icons-register';
import { MukiWSService } from 'src/app/service/mukiws/mukiws.service';

@NgModule({
   declarations: [
      AppComponent,
   ],
   imports: [
      HttpClientModule,
      BrowserModule,
      AppRoutingModule,
      PlayerModule,
      SideNavModule,
      WrapperModule
   ],
   providers: [IconsRegister, MukiWSService],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
