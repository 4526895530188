import { Component, OnInit } from '@angular/core';
import { MukiWSService } from 'src/app/service/mukiws/mukiws.service';

@Component({
  selector: 'app-list-room',
  templateUrl: './list-room.component.html',
  styleUrls: ['./list-room.component.scss'],
})
export class ListRoomComponent implements OnInit {
  constructor(private mukiWS: MukiWSService) {}
  public rooms: any;
  ngOnInit() {
    setTimeout(async () => {
      this.rooms = await this.mukiWS.sendRequest({
        type: 'room.list',
      });

      console.log(this.rooms);
    }, 1000);
  }
}
