import { Component } from '@angular/core';
import { IconsRegister } from 'src/lib/provider/icons/icons-register';
import { MukiWSService } from 'src/app/service/mukiws/mukiws.service';
import 'hammerjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'muki-client-beta';

  constructor(
    private iconsRegister: IconsRegister,
    private mukiWS: MukiWSService
  ) {
    iconsRegister.initIconRegistry();

    setTimeout(async () => {
      const rooms = await mukiWS.sendRequest({
        type: 'room.list',
      });

      console.log(rooms);
    }, 1000);
  }
}
