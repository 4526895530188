<div class="user-info_container">
  <div class="user-account-title" fxLayout="row" fxLayoutAlign="space-between center">
    <p class="user-account-title-label">
      Mon compte
    </p>
    <div class="user-account-title-button">
      <mat-icon>pending</mat-icon>
    </div>
  </div>
  <div class="user-account-infos" fxLayout="row" fxLayoutAlign="none center"> 
    <div class="user-account-infos-avatar-icon">
      <mat-icon inline="true" svgIcon="muki:avatar_shape"></mat-icon>
    </div>
  <div class="user-account-infos-identity" fxLayout="column" fxLayoutAlign="center center">
    <p>Pseudo</p>
    <p>#OOOO</p>
  </div>

  </div>
</div>
