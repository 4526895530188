import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {FlexLayoutModule} from '@angular/flex-layout';

import { SideNavComponent } from './side-nav.component';
import { ListRoomComponent } from './list-room/list-room.component';
import { UserInfoComponent } from './user-info/user-info.component';

const sharedModules = [SideNavComponent, ListRoomComponent, UserInfoComponent];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FlexLayoutModule,
  ],
  declarations: [sharedModules],
  exports: [sharedModules, FlexLayoutModule],
  entryComponents: [sharedModules]
})
export class SideNavModule { }
