import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as lodash from 'lodash';
import { MatIconRegistry } from '@angular/material/icon';
import { iconsMap } from './icons-map';

@Injectable()
export class IconsRegister {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

  public initIconRegistry() {
    const icons = iconsMap.svgs;
    const assetsUrl = iconsMap.baseUrl || '';
    const baseUrl = iconsMap.url;
    const namespace = iconsMap.namespace;
    if (icons != null) {
      lodash.forEach(icons, (icon) => {
        this.iconRegistry.addSvgIconInNamespace(
          namespace,
          icon.name,
          this.sanitizer.bypassSecurityTrustResourceUrl(assetsUrl + baseUrl + icon.url)
        );
      });
    }
  }
}
