<div class="side-nav-container">
  <div class="logo">
   <mat-icon inline="true" svgIcon="muki:muki_logo_full"></mat-icon>
  </div>
  <app-user-info></app-user-info>
  <app-list-room></app-list-room>
  <p>
    side-nav works!
  </p>
</div>
