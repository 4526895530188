import { Middleware } from './Middleware';

interface WebSocketMiddleware {
	onOpen: Middleware,
	onMessage: Middleware,
	onClose: Middleware,
	onError: Middleware
};

interface PromiseCallback {
	resolve: Function;
	reject: Function;
};

interface MiddlewareRequest {
	client: WebSocketClient;
	data?: any;
	buffer?: ArrayBuffer;
}

export class WebSocketClient {
    name: string;
    url: string;

	mw: WebSocketMiddleware;
	ws: WebSocket;

    requestId: number;
    requestCallback: Map<number, PromiseCallback>;

    constructor(name, url) {
        this.name = name;
        this.url = url;

        this.mw = {
            onOpen: new Middleware(),
            onMessage: new Middleware(),
            onClose: new Middleware(),
            onError: new Middleware()
        };

        this.ws = new WebSocket(this.url);
        this.ws.onopen = (event) => {
			const req : MiddlewareRequest = { client: this };
			this.mw.onOpen.go(req);
		};

		this.ws.onmessage = (event) => {
			const req : MiddlewareRequest = {
                client: this,
            };

            if (event.data instanceof ArrayBuffer) {
                req.buffer = event.data;
            } else {
                req.data = JSON.parse(event.data.toString());
            }

			this.mw.onMessage.go(req);
		};

		this.ws.onclose = (event) => {
			const req : MiddlewareRequest = { client: this };
			this.mw.onClose.go(req);

			setTimeout(() => this.reconnect(), 5000);
		};

		this.ws.onerror = (event) => {
			const req : MiddlewareRequest = {
				client: this,
				data: event
			};
			this.mw.onError.go(req);
        };
        
        this.initLogger();

        this.requestId = 1;
		this.requestCallback = new Map();

		this.mw.onMessage.use((req, next) => {
			if (!this.handleRequest(req))
				next();
        });
    }

    initLogger() {
		this.mw.onOpen.use((req, next) => {
			console.log(`[${this.name}] Connected to ${this.url}.`);
			next();
		});

		this.mw.onMessage.use((req, next) => {
			console.log(`[${this.name}] Message :`);
			console.log(req.data);
			next();
		});

		this.mw.onClose.use((req, next) => {
			console.log(`[${this.name}] Disconnected.`);
			next();
		});

		this.mw.onError.use((req, next) => {
			console.error(`[${this.name}] Error : `, req.data);
			next();
		});
	}

	sendRequest(reqIn:any) {
		return new Promise((resolve, reject) => {
			if (this.ws.readyState !== WebSocket.OPEN) {
				reject(`WebSocket isn't open yet.`);
				return;
			}

			if (!reqIn || typeof reqIn !== 'object') {
				reject(`Malformed data from client side...`);
				return;
			}
			if (typeof reqIn.type === 'undefined') {
				reject(`Malformed data from client side...`);
				return;
			}

			let reqOut:any = {
				type: reqIn.type
			};

			if (typeof reqIn.data !== 'undefined')
				reqOut.data = reqIn.data;
			reqOut.id = this.requestId++;

			console.log(`[${this.name}] Request :`);
			console.log(reqOut);

			this.requestCallback.set(reqOut.id, { resolve, reject });

			this.ws.send(JSON.stringify(reqOut));
		});
	}

	handleRequest(req) {
		if (typeof req.data.type === 'undefined' && typeof req.data.id !== 'undefined') {
			const callback = this.requestCallback.get(req.data.id);

			if (typeof req.data.result !== 'undefined') {
				callback.resolve(req.data.result);
			} else {
				callback.reject(req.data.error);
			}
			return true;
		}
		return false;
	}

	sendData(data) {
		if (this.ws.readyState !== WebSocket.OPEN) {
			return;
		}

		console.log(`[${this.name}] Send :`);
		console.log(data);
		this.ws.send(JSON.stringify(data));
	}

	reconnect() {
		let new_ws = new WebSocket(this.url);
		new_ws.onopen = this.ws.onopen;
		new_ws.onmessage = this.ws.onmessage;
		new_ws.onclose = this.ws.onclose;
		new_ws.onerror = this.ws.onerror;

		this.ws = new_ws;
	}
}