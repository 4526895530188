<div class="list-room-container">
  <p class="list-room-title">Ma liste de salons</p>
  <div class="listened-room" fxLayout="row" fxLayoutAlign="none center">
    <div class="listened-room-icon">
      <mat-icon inline="true" svgIcon="muki:avatar_shape"></mat-icon>
    </div>
    <p>Nom du salon en cours d'écoute</p>
  </div>
  <div class="list-rooms">
    <ng-container *ngFor="let room of rooms">
      <div class="room-listed-container" fxLayout="row" fxLayoutAlign="none center">
        <div class="room-listed-icon">
          <mat-icon inline="true" svgIcon="muki:avatar_shape"></mat-icon>
        </div>
        <p class="room-listed-name">{{ room.name }}</p>
      </div>
    </ng-container>
  </div>
</div>
