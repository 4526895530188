import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrapperComponent } from './wrapper.component';

const sharedModules = [WrapperComponent];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [sharedModules],
  exports: [sharedModules],
  entryComponents: [sharedModules]
})
export class WrapperModule { }
