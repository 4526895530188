import { Injectable } from '@angular/core';

import { WebSocketClient } from 'src/lib/WebSocketClient/WebSocketClient';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MukiWSService {
  private wsc: WebSocketClient;

  constructor() {
    this.wsc = new WebSocketClient('MukiWS', environment.mukiws);
  }

  sendRequest(reqIn) {
    return this.wsc.sendRequest(reqIn);
  }
}
